<template>
    <div>
        <b-tabs content-class="mt-1">
            <b-tab title="Asistencias">
                <asistencias />
            </b-tab>
            <b-tab title="Historial" lazy>
                <historial-asistencias />
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import { ref, onMounted } from "@vue/composition-api";
export default {
    components: {
        Asistencias: () => import("./tabs/Asistencias.vue"),
        HistorialAsistencias: () => import("./tabs/HistorialAsistencias.vue"),
    },
    setup(props) {},
};
</script>
